var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        attrs: {
          title: "",
          columns: _vm.checkGrid.columns,
          data: _vm.checkGrid.data,
          customDataTr: "",
          gridHeightAuto: "",
          hideHeader: "",
          hideBottom: "",
          isDense: "",
          isTitle: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customDataTr",
            fn: function (props) {
              return [
                props.rowIndex === 0
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style th1-style",
                            attrs: { rowspan: 1, colspan: 4 },
                          },
                          [_c("span", [_vm._v(" 상태값 정보 ")])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 1
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-light-blue" },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "light-blue",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" 적용가능 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c("div", { staticClass: "text-light-blue" }, [
                              _vm._v(
                                " 구성물질이 가지는 규제정보를 기준에 만족하는 경우, 여러 구성물질 중 모든 기준에 만족하는 경우를 일컬음 "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-grey" },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "grey",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" 대기 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c("div", { staticClass: "text-grey" }, [
                              _vm._v(
                                " 구성물질이 가지는 규제정보의 기준이 정해져 있지 않은 경우 ,summary해서 적용한다고 알려주되, 선택할 수 있게 처리 ,모든 구성물질이 규제정보 기준이 정해지지 않은 경우 "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 2
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-green" },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "green",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" 적용가능 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c("div", { staticClass: "text-green" }, [
                              _vm._v(
                                " 구성물질이 가지는 규제정보를 기준에 만족하는 경우, 하지만 여러 구성물질 중 단 하나라도 기준에 만족하지 않는 경우가 포함된 것을 일컬음 "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-orange" },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "orange",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" 대기 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c("div", { staticClass: "text-orange" }, [
                              _vm._v(
                                " 구성물질이 가지는 규제정보의 기준이 정해져 있지 않은 경우 ,summary해서 적용한다고 알려주되, 선택할 수 있게 처리 ,여러 구성물질 중 하나 이상이 규제정보 기준이 정해지지 않았으며 그 외의 경우는 적용불가인 경우 "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 3
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-red" },
                              [
                                _c(
                                  "q-chip",
                                  {
                                    attrs: {
                                      color: "red",
                                      "text-color": "white",
                                    },
                                  },
                                  [_vm._v(" 적용불가 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [
                            _c("div", { staticClass: "text-red" }, [
                              _vm._v(
                                " 구성물질이 가지는 규제정보를 기준에 만족하지 않는 경우 "
                              ),
                            ]),
                          ]
                        ),
                        _c("q-td", {
                          staticClass: "text-center th-td-style td1-style",
                          attrs: { rowspan: 1, colspan: 1 },
                        }),
                        _c("q-td", {
                          staticClass: "text-left th-td-style td2-style",
                          attrs: { rowspan: 1, colspan: 1 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "c-table",
        {
          ref: "table-result",
          attrs: {
            title: "검증결과",
            columns: _vm.gridResult.columns,
            data: _vm.gridResult.data,
            merge: _vm.gridResult.merge,
            gridHeight: _vm.gridHeight,
            usePaging: false,
            filtering: false,
            isExcelDown: false,
            isFullScreen: false,
            columnSetting: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "statusFin"
                    ? [
                        props.row.statusFin === "Y1"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color: "light-blue",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" 적용가능 ")]
                              ),
                            ]
                          : props.row.statusFin === "Y2"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color: "green",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" 적용가능 ")]
                              ),
                            ]
                          : props.row.statusFin === "N"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color: "red",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" 적용불가 ")]
                              ),
                            ]
                          : props.row.statusFin === "W1"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color: "grey",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" 대기 ")]
                              ),
                            ]
                          : props.row.statusFin === "W2"
                          ? [
                              _c(
                                "q-chip",
                                {
                                  attrs: {
                                    color: "orange",
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" 대기 ")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.saveData,
                      mappingType: "PUT",
                      label: "적용",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveInfo,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-table", {
        attrs: {
          title: "",
          columns: _vm.regulGrid.columns,
          data: _vm.regulGrid.data,
          customDataTr: "",
          gridHeightAuto: "",
          hideHeader: "",
          hideBottom: "",
          isDense: "",
          isTitle: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customDataTr",
            fn: function (props) {
              return [
                props.rowIndex === 0
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style th2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [_c("span", [_vm._v(" AS-IS ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style th2-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          [_c("span", [_vm._v(" TO-BE ")])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 1
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td1-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          _vm._l(_vm.asis.bills, function (item, idx) {
                            return _c(
                              "div",
                              {
                                key: `asis-bill${idx}`,
                                staticClass: "divchklaw",
                              },
                              [
                                _c("div", { staticClass: "lawspan" }, [
                                  _vm._v(_vm._s(item.regulationBillName)),
                                ]),
                                _vm._l(item.reguls, function (regul, _idx) {
                                  return _c("q-checkbox", {
                                    key: `asis-regul${_idx}`,
                                    staticClass: "customqcbox",
                                    attrs: {
                                      dense: "",
                                      color: "orange-custom",
                                      "true-value": "Y",
                                      "false-value": "N",
                                      disable: true,
                                      label: regul.regulatedItemName,
                                    },
                                    model: {
                                      value: regul.val,
                                      callback: function ($$v) {
                                        _vm.$set(regul, "val", $$v)
                                      },
                                      expression: "regul.val",
                                    },
                                  })
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-left th-td-style td3-style",
                            attrs: { rowspan: 1, colspan: 1 },
                          },
                          _vm._l(_vm.tobe.bills, function (item, idx) {
                            return _c(
                              "div",
                              {
                                key: `tobe-bill${idx}`,
                                staticClass: "divchklaw",
                              },
                              [
                                _c("div", { staticClass: "lawspan" }, [
                                  _vm._v(_vm._s(item.regulationBillName)),
                                ]),
                                _vm._l(item.reguls, function (regul, _idx) {
                                  return _c("q-checkbox", {
                                    key: `tobe-regul${_idx}`,
                                    staticClass: "customqcbox",
                                    attrs: {
                                      dense: "",
                                      color: "orange-custom",
                                      "true-value": "Y",
                                      "false-value": "N",
                                      label: regul.regulatedItemName,
                                    },
                                    model: {
                                      value: regul.val,
                                      callback: function ($$v) {
                                        _vm.$set(regul, "val", $$v)
                                      },
                                      expression: "regul.val",
                                    },
                                  })
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }